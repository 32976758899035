.app {
  text-align: center;
  height:100%;
  background-color: #0A0F0D;
}


.monkey {
  stroke: #FFC300;
  stroke-width: 2px;
  stroke-dashoffset: 2000;
  stroke-dasharray: 2000;
  animation-name: monkey;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes monkey {
  0% {
    stroke-dashoffset: 2000;
    stroke: #FFC300;
  }
  30% {
    stroke: #FF5733;
  }
  50% {
    stroke: #FFC300;
  }
  70% {
    stroke: #FF5733;
  }
  100% {
    stroke-dashoffset:4000;
    stroke: #FFC300;
  }
}

#screenplay {
  width:70%;
  height:100%;
  margin: 0 auto;
  overflow-y: hidden;
}